// V3 design
.btn-maj
  text-transform: uppercase
  font-family: Montserrat, sans-serif
  font-weight: bold

.btn-p3
  padding: 0.5rem 1.2rem

//- Text utilities

.text-gray3
  color: $cobs_black_3

.text-lg
  font-size: 1.5em

.text-2x
  font-size: 2em


// Don't underline links by default
a
  text-decoration: none


// Use this for "fixed" display layout - same as v2
// body
//   background-color: $cobs_lightgrey
h1, h2, h3, h4
  font-family: $font-title

h1
  font-family: $cobs_title_font
  font-size: 2.5rem
  font-weight: bold
  color: $cobs_black_1
  text-align: left
  margin: 0.15rem 0
  padding: 0.15rem 0

  a
    color: $cobs_black_1

  a:hover
    color: $cobs_black_2
    text-decoration: none

  @include media-breakpoint-down(md)
    font-size: 1.9rem

  small
    margin-left: 1rem

h2
  font-weight: bold

  a
    color: inherit
    @include media-breakpoint-down(md)
      font-size: 1.5rem

  small
    margin-left: 1rem

h3
  color: $primary

  a
    color: inherit

  @include media-breakpoint-down(md)
    font-size: 1.4rem


h1.sectiontitle
  margin: 0
  padding: 1rem 0
  font-weight: bolder

h1.pagetitle
  margin: 0
  padding: 0.2rem 0
  font-weight: bolder
  border-bottom: 1px solid $hr-border-color

h1.sep
  border-top: 1px solid $cobs_black_1
  padding-top: 0.4rem
  margin-top: 0.3rem
  text-align: left

//------------------------------------------------------------------
// Special
.todo
  color: red
  background-color: yellow
  padding: 0.5rem


em.hlt1
  color: $cobs_red
  font-weight: bold

.blurred
  color: transparent !important
  text-shadow: 0 0 9px transparentize($cobs_blue, 0.5) !important

// Add missing pointer style
.pointer
  cursor: pointer

// Add missing card-header style
.card-header.ch-1
  font-size: 1.4rem
  font-weight: bold
  margin-top: 4px
  margin-bottom: 4px

  small, .help
    display: block
    text-transform: none
    font-size: 0.9rem

  @include media-breakpoint-down(sm)
    font-size: 1.2rem

button:focus, a:focus, a:active, div:focus, div:active
  outline: 0


// Make carousel buttons more visible
ngb-carousel .carousel-control-next, ngb-carousel .carousel-control-prev
  background-color: transparent
  align-items: flex-end
  padding-bottom: 20px
  width: 10%

ngb-carousel .carousel-control-next:hover, ngb-carousel .carousel-control-prev:hover
  background-color: transparentize($cobs_blue, 0.94)

ngb-carousel .carousel-caption
  background-color: transparentize($cobs_blue, 0.9)
  bottom: 0 !important

  h3, p
    color: white
    text-shadow: 2px 2px 4px black

ngb-carousel .carousel-control-next-icon
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e")
  background-position-y: 93%
  background-size: 15px 15px

ngb-carousel .carousel-control-prev-icon
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e")
  background-position-y: 93%
  background-size: 15px 15px


ngb-carousel .carousel-indicators li
  box-sizing: content-box
  flex: 0 1 auto
  width: $carousel-indicator-width
  height: $carousel-indicator-height
  padding: 0
  margin-right: $carousel-indicator-spacer
  margin-left: $carousel-indicator-spacer
  text-indent: -999px
  cursor: pointer
  background-color: $carousel-indicator-active-bg
  background-clip: padding-box
  border: 0
  // Use transparent borders to increase the hit area by 10px on top and bottom.
  border-top: $carousel-indicator-hit-area-height solid transparent
  border-bottom: $carousel-indicator-hit-area-height solid transparent
  opacity: $carousel-indicator-opacity
  @include transition($carousel-indicator-transition)

// BOOTSTRAP FIXES
.pagination
  margin-top: 0.5rem
  margin-bottom: 0.5rem

.rightgraph
  color: white
  background-color: $cobs_blue !important

  .rcontent
    width: 100%
    height: 100%
    overflow: hidden
    padding: 20px
    display: flex
    align-items: center
    justify-content: center
  // border: 1px solid green
  //.rcontentbody
  //  border: 1px solid red


  .rmcontent
    .rmcontentpad
      overflow: hidden
      height: 200px
    padding: 20px

  p
    color: white
    font-weight: normal
    font-size: 0.8rem !important

  span.date, span.categ:before
    color: white !important

.form-group
  margin-bottom: 1rem

.bg-lighter
  background-color: #f8f9fa !important
