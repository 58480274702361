.contentpart
  .img-xxxs, img.xxxs, .img-xxxsm, img.xxsm
    width: 1.1rem
    max-width: 100%

  .img-xxs, img.xxs, .img-xxsm, img.xxsm
    width: 2.2rem
    max-width: 100%

  .img-xs, img.xs, .img-xsm, img.xsm
    width: 9.1rem
    max-width: 100%

  .img-sm, img.sm
    width: 4.5rem
    max-width: 100%

  .img-md, img.md
    width: 18rem
    max-width: 100%

  .img-lg, img.lg
    width: 36.5rem
    max-width: 100%

  .img-xl, img.xl
    width: 60rem
    max-width: 100%

  .img-xxl, img.xxl
    width: 90rem
    max-width: 100%

  .img-xxxl, img.xxxl
    width: 120rem
    max-width: 100%

  img.center
    display: block
    margin-left: auto
    margin-right: auto

  @include media-breakpoint-up(md)
    img.left
      float: left
      margin: 1rem 1rem 1rem 0
    img.right
      float: right
      margin: 1rem 0 1rem 1rem
  @include media-breakpoint-down(md)
    img.left, img.right
      display: block
      margin-left: auto !important
      margin-right: auto !important
      margin-top: 1rem
      margin-bottom: 1rem

  img.round
    border-radius: 5px

  img.thumbnail
    border: 1px solid lightgrey
    padding: 2px

  img
    max-width: 100% !important

  span.rouge
    color: $cobs_red

  p, ul, ol, td, th
    font-size: 1rem

  ul, ol
    margin-left: 0
    padding-left: 1.5rem

    li
      margin-left: 0

  table
    margin-top: 1.2rem
    margin-bottom: 1.2rem
    border: 1px solid lightgrey
    border-collapse: collapse
    padding: 0.1rem

    tr
      padding: 0.4rem

    td, th
      border: 1px solid lightgrey
      padding: 0.4rem

    th
      background-color: lightgrey

  blockquote
    font-style: italic

  h3
    font-size: 1.4rem
