.forum-msgcontent
  blockquote
    padding-left: 2rem
    border-left: 2px solid blue

    .from
      font-weight: bold

  .popover
    max-width: 70vw

    .popover-body .forum-content
      max-height: 30vh
      overflow: auto

.forum-inreplyto
  border-left: 1px solid $cobs_black_2
  padding: 0.1rem 1rem
  font-style: italic
  margin-bottom: 1rem
  color: $cobs-black_3

  .author
    color: $cobs_blue
    font-weight: bold

.pagination-xs .pagination-sm .page-link
  padding: 0.1rem 0.3rem
