// GLOBAL VARIABLES
$cobs_base_font_size: 1rem;
$cobs_base_print_size: 12pt;

$cobs_base_font: "Ubuntu", sans-serif;
$cobs_title_font: "Montserrat", sans-serif;

// SIZES
$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 1024px, xl: 1400px, xxl: 1600px);
$container-max-widths: (sm: 540px, md: 720px, lg: 996px, xl: 1380px, xxl: 1539px);

//

// COLORS
$cobs_light_bg: #fcfcfc;
$cobs_bg: #f8f8f8;
$cobs_blue: #2e3192;
$cobs_red: #d32439;
$cobs_black_1: #2a2a2e;
$cobs_black_2: #444444;
$cobs_black_3: #666666;
$cobs_bg_grey_1: #e7e7e7;
$cobs_bg_grey_2: #f0f0f0;
$cobs_bg_grey_3: #d9d9d9;

$cobs_green: green;
$cobs_grey: $cobs_bg_grey_1;
$cobs_lightgrey: $cobs_bg_grey_2;


// BOOSTRAP VARIABLES
// Global bootstrap values
// bs colors
$body-bg: $cobs_light_bg;
$blue: $cobs_blue;
$cyan: lighten($cobs_blue, 20);
$red: $cobs_red;

$gray-lighter: $cobs_lightgrey;
$font-size-base: $cobs_base_font_size;
$font-size-lg: $font-size-base;
$font-size-sm: $font-size-base;

$btn-border-radius: 0;

$enable-shadows: false;
$enable-transitions: true;
$theme-colors: (
  "primary": $cobs_blue,
  "secondary": $cobs_bg_grey_1,
  "success": $cobs_green,
  "info": $cyan,
  "warning": #fd7e14,
  "danger": $cobs_red,
  "light": $cobs_bg_grey_1,
  "dark": $cobs-black_3,
  "black1": $cobs_black_1,
  "black2": $cobs_black_2,
  "black3": $cobs_black_3,
  "grey1": $cobs_bg_grey_1,
  "grey2": $cobs_bg_grey_2
);
$text-muted: $cobs_bg_grey_3;

// bs fonts
$font-title: $cobs_title_font;
$font-overtitle: $cobs_title_font;
$font-family-sans-serif: $cobs_base_font;


// breadcrumbs
$breadcrumb-border-radius: 0;
$breadcrumb-divider-color: $cobs_black_2;
$breadcrumb-active-color: $cobs_black_3;
$breadcrumb-bg: white;
$breadcrumb-margin-bottom: 0;
$breadcrumb-font-size: 0.9rem;
$breadcrumb-padding-x: 0.2rem;

// cards
$card-border-radius: 0;
$card-border-width: 0;
$card-cap-bg: inherit;
$card-inner-border-radius: 0;
$card-spacer-y: 0.35rem;
$card-spacer-x: 1rem;

// hr
$hr-border-color: $cobs_bg_grey_3;
$hr-border-width: 1.5px;

// inputs and forms
$input-border-radius: 0;
$input-border-color: $cobs_bg_grey_2;

// buttons
$btn-border-radius: 0;
$btn-border-radius-sm: 0;
$btn-border-radius-lg: 0;

// dropdown
$dropdown-border-radius: 0;

$xsm_ratio: 0.6;
$sm_ratio: 0.8;
$md_ratio: 1;
$lg_ratio: 1.2;
$xl_ratio: 1.6;
$xxl_ratio: 1.9;

// carousel
$carousel-indicator-width: 30px;
$carousel-indicator-height: 4px;
$carousel-indicator-hit-area-height: 15px;
$carousel-indicator-spacer: 4px;
$carousel-indicator-opacity: .5;
$carousel-indicator-active-bg: #000;
$carousel-indicator-active-opacity: 1;
$carousel-indicator-transition: opacity .6s ease;
