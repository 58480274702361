@import "assets/fonts/fontello-3fd596b7/css/fontello.css"
@import "assets/fonts/fontello-3fd596b7/css/animation.css"
// Icons

.icon-sm
  font-size: 0.7rem

.icon-lg
  font-size: 1.2rem

.icon-2x
  font-size: 2rem

.icon-3x
  font-size: 3rem

.icon-4x
  font-size: 4rem

.icon-5x
  font-size: 5rem

.icon-6x
  font-size: 6rem

.icon-7x
  font-size: 7rem

.icon-8x
  font-size: 8rem

.icon-9x
  font-size: 9rem

.icon-10x
  font-size: 10rem
