ul.errorlist, .form-control-feedback
  margin-left: 0
  padding-left: 0
  margin-top: 0.2rem
  font-size: 0.9rem
  line-height: 1rem

  li
    list-style: none
    padding-left: 0
    margin-left: 0

ul.errorlist li
  color: $cobs_red

.form-group.has-danger input, .form-group.has-danger textarea, .form-group.has-danger select, input.ng-invalid.ng-dirty, textarea.ng-invalid.ng-dirty, select.ng-invalid.ng-dirty, input.ng-invalid.ng-touched, textarea.ng-invalid.ng-touched, select.ng-invalid.ng-touched
  border: 1px solid $cobs_red
  border-left: 6px solid $cobs_red

.form-group.has-success input, .form-group.has-success textarea, .form-group.has-success select, input.ng-valid, textarea.ng-valid, select.ng-valid
  border-left: 6px solid $cobs_green

.form-invalid
  font-size: 0.9rem
  color: $cobs_red

.form-group label
  margin-bottom: 0
