@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?35730565');
  src: url('../font/fontello.eot?35730565#iefix') format('embedded-opentype'),
  url('../font/fontello.woff2?35730565') format('woff2'),
  url('../font/fontello.woff?35730565') format('woff'),
  url('../font/fontello.ttf?35730565') format('truetype'),
  url('../font/fontello.svg?35730565#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?35730565#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-login:before {
  content: '\e800';
}

/* '' */
.icon-user:before {
  content: '\e801';
}

/* '' */
.icon-lock:before {
  content: '\e802';
}

/* '' */
.icon-eye:before {
  content: '\e803';
}

/* '' */
.icon-key:before {
  content: '\e804';
}

/* '' */
.icon-attention:before {
  content: '\e805';
}

/* '' */
.icon-ok:before {
  content: '\e806';
}

/* '' */
.icon-cog:before {
  content: '\e807';
}

/* '' */
.icon-cog-alt:before {
  content: '\e808';
}

/* '' */
.icon-floppy:before {
  content: '\e809';
}

/* '' */
.icon-link:before {
  content: '\e80a';
}

/* '' */
.icon-print:before {
  content: '\e80b';
}

/* '' */
.icon-gift:before {
  content: '\e80c';
}

/* '' */
.icon-download:before {
  content: '\e80d';
}

/* '' */
.icon-right-dir:before {
  content: '\e80e';
}

/* '' */
.icon-down-dir:before {
  content: '\e80f';
}

/* '' */
.icon-heart:before {
  content: '\e810';
}

/* '' */
.icon-heart-empty:before {
  content: '\e811';
}

/* '' */
.icon-cancel:before {
  content: '\e812';
}

/* '' */
.icon-pencil:before {
  content: '\e813';
}

/* '' */
.icon-edit:before {
  content: '\e814';
}

/* '' */
.icon-plus:before {
  content: '\e815';
}

/* '' */
.icon-basket:before {
  content: '\e816';
}

/* '' */
.icon-tag:before {
  content: '\e817';
}

/* '' */
.icon-credit-card:before {
  content: '\e818';
}

/* '' */
.icon-volume-up:before {
  content: '\e819';
}

/* '' */
.icon-volume-down:before {
  content: '\e81a';
}

/* '' */
.icon-bold:before {
  content: '\e81b';
}

/* '' */
.icon-videocam:before {
  content: '\e81c';
}

/* '' */
.icon-italic:before {
  content: '\e81d';
}

/* '' */
.icon-thumbs-up:before {
  content: '\e81e';
}

/* '' */
.icon-level-up:before {
  content: '\e81f';
}

/* '' */
.icon-info-circled:before {
  content: '\e820';
}

/* '' */
.icon-comment:before {
  content: '\e821';
}

/* '' */
.icon-ok-circled:before {
  content: '\e822';
}

/* '' */
.icon-search:before {
  content: '\e823';
}

/* '' */
.icon-phone:before {
  content: '\e824';
}

/* '' */
.icon-mail:before {
  content: '\e825';
}

/* '' */
.icon-folder-open:before {
  content: '\e826';
}

/* '' */
.icon-home:before {
  content: '\e827';
}

/* '' */
.icon-dot-3:before {
  content: '\e828';
}

/* '' */
.icon-bookmark:before {
  content: '\e829';
}

/* '' */
.icon-star:before {
  content: '\e82a';
}

/* '' */
.icon-star-empty:before {
  content: '\e82b';
}

/* '' */
.icon-gauge:before {
  content: '\e82c';
}

/* '' */
.icon-up-dir:before {
  content: '\e82d';
}

/* '' */
.icon-left-dir:before {
  content: '\e82e';
}

/* '' */
.icon-logout:before {
  content: '\e82f';
}

/* '' */
.icon-folder:before {
  content: '\e830';
}

/* '' */
.icon-sync:before {
  content: '\e831';
}

/* '' */
.icon-calendar:before {
  content: '\e832';
}

/* '' */
.icon-bookmark-empty:before {
  content: '\f097';
}

/* '' */
.icon-twitter:before {
  content: '\f099';
}

/* '' */
.icon-facebook:before {
  content: '\f09a';
}

/* '' */
.icon-list-bullet:before {
  content: '\f0ca';
}

/* '' */
.icon-underline:before {
  content: '\f0cd';
}

/* '' */
.icon-money:before {
  content: '\f0d6';
}

/* '' */
.icon-linkedin:before {
  content: '\f0e1';
}

/* '' */
.icon-doc-text:before {
  content: '\f0f6';
}

/* '' */
.icon-angle-left:before {
  content: '\f104';
}

/* '' */
.icon-angle-right:before {
  content: '\f105';
}

/* '' */
.icon-spinner:before {
  content: '\f110';
}

/* '' */
.icon-reply:before {
  content: '\f112';
}

/* '' */
.icon-reply-all:before {
  content: '\f122';
}

/* '' */
.icon-rss-squared:before {
  content: '\f143';
}

/* '' */
.icon-ticket:before {
  content: '\f145';
}

/* '' */
.icon-bug:before {
  content: '\f188';
}

/* '' */
.icon-bank:before {
  content: '\f19c';
}

/* '' */
.icon-google:before {
  content: '\f1a0';
}

/* '' */
.icon-file-pdf:before {
  content: '\f1c1';
}

/* '' */
.icon-bomb:before {
  content: '\f1e2';
}

/* '' */
.icon-newspaper:before {
  content: '\f1ea';
}

/* '' */
.icon-wifi:before {
  content: '\f1eb';
}

/* '' */
.icon-trash:before {
  content: '\f1f8';
}

/* '' */
.icon-facebook-official:before {
  content: '\f230';
}

/* '' */
.icon-sticky-note:before {
  content: '\f249';
}

/* '' */
.icon-sticky-note-o:before {
  content: '\f24a';
}

/* '' */
.icon-map-signs:before {
  content: '\f277';
}

/* '' */
.icon-commenting:before {
  content: '\f27a';
}

/* '' */
.icon-question-circle-o:before {
  content: '\f29c';
}

/* '' */
.icon-envelope-open-o:before {
  content: '\f2b7';
}

/* '' */
