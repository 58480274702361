/*!
 * Bootstrap v5.1.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */


// Custom.scss
// Option B: Include parts of Bootstrap

// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import "bootstrap/scss/mixins/banner"
@include bsBanner("")
@import "bootstrap/scss/functions"

// 2. Include any default variable overrides here

// 3. Include remainder of required Bootstrap stylesheets
@import "bootstrap/scss/variables"
@import "bootstrap/scss/variables-dark"

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import "bootstrap/scss/maps"
@import "bootstrap/scss/mixins"
@import "bootstrap/scss/utilities"


// 6. Optionally include any other parts as needed
@import "bootstrap/scss/root"
@import "bootstrap/scss/reboot"
@import "bootstrap/scss/type"
@import "bootstrap/scss/images"
@import "bootstrap/scss/containers"
@import "bootstrap/scss/grid"
@import "bootstrap/scss/helpers"
@import "bootstrap/scss/buttons"
@import "bootstrap/scss/transitions"
@import "bootstrap/scss/dropdown"
@import "bootstrap/scss/nav"
@import "bootstrap/scss/navbar"
@import "bootstrap/scss/card"
// @import "bootstrap/scss/accordion"
@import "bootstrap/scss/breadcrumb"
@import "bootstrap/scss/pagination"
@import "bootstrap/scss/badge"
@import "bootstrap/scss/alert"
// @import "bootstrap/scss/progress"
@import "bootstrap/scss/list-group"
@import "bootstrap/scss/close"
// @import "bootstrap/scss/toasts"
//@import "bootstrap/scss/modal"
//
//@import "bootstrap/scss/tooltip"
//
//@import "bootstrap/scss/popover"

// @import "bootstrap/scss/carousel"
// @import "bootstrap/scss/spinners"
// @import "bootstrap/scss/offcanvas"
// @import "bootstrap/scss/placeholde


// 7. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import "bootstrap/scss/helpers"
@import "bootstrap/scss/utilities/api"

// 8. Add additional custom code here


